<template>
	<div
		class="order-form"
	>
		<div
			class="hide-pc full-height pb-30  ma-auto"
		>
			<div class="">

				<h6>주문정보</h6>
				<div class="pa-10 box-shadow bg-white">
					<div
						class="justify-space-between font-weight-bold under-line-dashed pb-10"
					>
						<span>주문번호</span>
						<span>{{ item.order_number }}</span>
					</div>

					<div
						class="mt-10 justify-space-between"
					>
						<span class="font-weight-bold">주문일시</span>
						<span>{{ item.wDate }}</span>
					</div>
				</div>
			</div>

			<div class="mt-10">
				<h6>주문자 정보</h6>
				<div
					class="pa-10 bg-white box-shadow"
				>
					<div class=" justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">이름</span>
						<span>{{ item.member_name }}</span>
					</div>
					<div class="mt-5  justify-space-between under-line-dashed pb-10">
						<span class="font-weight-bold">연락처</span>
						<span>{{ item.member_tell }}</span>
					</div>
					<div class="mt-5  justify-space-between ">
						<span class="font-weight-bold">이메일</span>
						<span>{{ item.member_email }}</span></div>
				</div>
			</div>

			<div class="mt-10">
				<h6>상품 정보</h6>
				<div></div>
			</div>

			<template
				v-if="item.supply_list.length > 0"
			>
				<ul class="">
					<li
						v-for="(supply, item_index) in item_list"
						:key="item_index"
						class="under-line mb-10  bg-white box-shadow"
					>
						<div
							class="pa-10 under-line font-weight-bold"
						><v-icon>mdi mdi-home-modern</v-icon>{{ supply.shop_name }}</div>
						<ul>
							<li
								v-for="(odt, product_index) in supply.odt_list"
								:key="'product_' + product_index"
								class="pa-10 under-line full-height justify-space-between"
							>
								<div class="flex-1 full-height pdt-img justify-center flex-column mr-10">
									<img
										v-if="odt.pdt_img"
										:src="odt.pdt_img"
									/>
									<v-icon
										v-else
									>mdi mdi-image</v-icon>
								</div>
								<div class="flex-3">
									<div class="under-line justify-space-between">
										{{ odt.pdt_name }}
									</div>
									<div
										class="under-line-dashed"
									>
										<div class="  color-gray">
											<span>선택옵션: </span>
											<span>{{ odt.op_name}}</span>
										</div>
										<div
											class=" justify-space-between"
										>
											<span
												class="flex-2 font-weight-bold"
											>{{ odt.pdt_price | makeComma }} 원</span>

											<span>수량: {{ odt.op_cnt }} 개</span>
										</div>
									</div>
								</div>
							</li>
						</ul>
						<div
							class="pa-10 justify-space-between under-line-dashed"
						>
							<span class="font-weight-bold">상품 합계</span>
							<span class="color-blue font-weight-bold">{{ supply.total_price | makeComma }} 원</span>
						</div>
						<div
							v-if="false"
							class="mt-10 prl-10 justify-space-between "
						>
							<span class="font-weight-bold">배송비 <span class="size-px-11">{{ supply.delivery_type_agency }}</span></span>
							<span class="font-weight-bold">{{ supply.delivery_price_agency | makeComma }} 원</span>
						</div>
						<div
							v-if="supply.island_delivery > 0"
							class="prl-10 mb-10 justify-space-between "
						>
							<span class="font-weight-bold">도서 산간 추가배송비</span>
							<span class="font-weight-bold">{{ supply.island_delivery | makeComma }} 원</span>
						</div>
					</li>
				</ul>
			</template>

			<h6 class="mt-10">결제 정보</h6>
			<div
				class="pa-10 bg-white box-shadow font-weight-bold"
			>
				<div class=" justify-space-between under-line-dashed">
					<span class="font-weight-bold">결제금액</span>
					<span><span class="color-blue font-weight-bold">{{ item.order_price | makeComma }}</span> 원</span>
				</div>
				<div class=" mt-5  justify-space-between under-line-dashed">
					<span class="font-weight-bold">결제</span>
					<span>{{ pay_div }}</span>
				</div>
				<div class=" mt-5  justify-space-between under-line-dashed">
					<span class="">결제 상태</span>
					<span>{{ order_status }}</span>
				</div>
				<div v-if="false" class=" mt-5 text-right ">
					{{ item.pay_info }}
				</div>
			</div>
		</div>
		<div
			class="hide-m full-height pb-30  ma-auto"
		>
			<div class="">

				<h4 class="color-primary">주문정보</h4>
				<table class="mt-10 table">
					<colgroup>
						<col width="120px" />
						<col width="auto" />
					</colgroup>
					<tbody>
					<tr>
						<th>주문번호</th>
						<td class="td-left">{{ item.order_number }}</td>
					</tr>

					<tr>
						<th class="font-weight-bold">주문일시</th>
						<td class="td-left">{{ item.wDate }}</td>
					</tr>
					</tbody>
				</table>
			</div>

			<div class="mt-30">
				<h4  class="color-primary">주문자 정보</h4>
				<table class="mt-10 table">
					<colgroup>
						<col width="120px" />
						<col width="auto" />
					</colgroup>
					<tbody>
					<tr>
						<th>이름</th>
						<td class="td-left">{{ item.member_name }}</td>
					</tr>
					<tr>
						<th>연락처</th>
						<td class="td-left">{{ item.member_tell }}</td>
					</tr>
					<tr>
						<th>이메일</th>
						<td class="td-left">{{ item.member_email }}</td>
					</tr>
					</tbody>
				</table>
			</div>

			<div
				v-if="false"
				class="mt-10"
			>

				<h6>배송 구분</h6>

				<div
					class="bg-white pa-10 box box-shadow font-weight-bold"
				>
					{{ item.delivery_type == 'D002002' ? '방문 수령' : '택배 배송'}}
				</div>
			</div>

			<div class="mt-30">
				<h4 class="color-primary">상품 정보</h4>
			</div>

			<template
				v-if="item.supply_list.length > 0"
			>
				<ul class="mt-10">
					<li
						v-for="(supply, item_index) in item_list"
						:key="item_index"
						class=" mb-10  "
					>
						<table class="table">
							<colgroup>
								<col width="140px" />
								<col width="auto" />
								<col width="120px" />
								<col width="120px" />
								<col width="120px" />
								<col width="120px" />
							</colgroup>
							<thead>
							<tr>
								<th colspan="2">상품명</th>
								<th>상품가</th>
								<th>수량</th>
								<th>합계</th>
								<th>주문 상태</th>
							</tr>
							</thead>
							<tbody>
							<tr
								v-for="(odt, product_index) in supply.odt_list"
								:key="'product_' + product_index"
								class="pa-10 under-line full-height "
							>
								<td>
									<div class=" pdt-img justify-center flex-column mr-10">
										<img
											v-if="odt.pdt_img"
											:src="odt.pdt_img"
											class="width-100"
										/>
										<v-icon
											v-else
										>mdi mdi-image</v-icon>
									</div>
								</td>
								<td>
									<div class="flex-1 text-left">
										<div class="under-line-dashed pb-10">
											{{ odt.pdt_name }}
										</div>

										<div class="under-line-dashed  color-gray  ptb-10 mb-10">
											<span>선택옵션: </span>
											<span>{{ odt.op_name}}</span>
										</div>
									</div>
								</td>
								<td>{{ odt.pdt_price | makeComma }} 원</td>
								<td>{{ odt.op_cnt }} 개</td>
								<td>{{ odt.pdt_price * odt.op_cnt | makeComma }} 원</td>
								<td>

									<div
										class="flex-1 flex-column justify-center "
										:class="'color-' + odt.order_status_color"
									>{{ odt.order_status_name }}</div>

									<div class="flex-3 mt-10 size-px-10 text-right">
										<button
											v-if="odt.is_cancel && (!item.is_purchase || item.is_purchase == '0')"
											class=" btn-danger"
											@click="isOdtUpdate(odt,'step21')"
										>주문 취소</button>
										<button
											v-if="odt.is_confirm"
											class="btn-success"
											@click="toOdtConfirm(odt)"
										>구매 확정</button>
										<button
											v-if="odt.is_return && (!item.is_purchase || item.is_purchase == '0')"
											class="btn-orange"
											@click="toOdtReturn(odt)"
										>교환 / 반품</button>
									</div>

									<button
										v-if="odt.shipping_num"
										class="box prl-10 color-blue"
										@click="toShipping(odt)"
									>배송조회</button>

									<div
										v-if="odt.reason"
									>
										<div>{{ odt.reason_name}}</div>
										<div>{{ odt.reason_text}}</div>
									</div>
								</td>
							</tr>
							</tbody>
						</table>

						<table
							class="table"
						>
							<colgroup>
								<col width="auto" />
								<col width="120px" />
							</colgroup>
							<tbody>
							<tr>
								<td class="font-weight-600 text-right size-px-20">상품 합계</td>
								<td class="font-weight-600 color-blue   size-px-20">{{ supply.total_price | makeComma }} 원</td>
							</tr>
							</tbody>
						</table>
						<div
							v-if="false"
							class="pa-10 justify-space-between "
						>
							<span class="font-weight-bold">배송비 </span>
							<span class="font-weight-bold">{{ supply.delivery_price | makeComma }} 원</span>
						</div>
						<div
							v-if="supply.island_delivery > 0"
							class="mb-10 prl-10 justify-space-between "
						>
							<span class="font-weight-bold">도서산간 추가 배송비</span>
							<span class="font-weight-bold">{{ supply.island_delivery | makeComma }} 원</span>
						</div>
					</li>
				</ul>
			</template>

			<div
				v-if="is_m"
				class="mt-30"
			>
				<h4 class="color-primary">상품권 정보</h4>

				<table class="table  mt-10">
					<colgroup>
					</colgroup>
					<thead>
					<tr>
						<th>TID</th>
						<th>처리 상태</th>
						<th>처리 내용</th>
						<th>사용 상태</th>
					</tr>
					</thead>
					<tbody>
					<tr
						v-for="item in item.order_return"
						:key="item.uid"
					>
						<td>{{ item.tranSeq }}</td>
						<td>{{ item.tranStatus }}</td>
						<td>{{ item.resultStatus }}</td>
						<td :class="{'color-red' : item.orgTranSeq }">{{ item.orgTranSeq ? '취소' : '정상' }}</td>
					</tr>
					</tbody>
				</table>
			</div>

			<div class="mt-30">
				<h4  class="color-primary">결제 정보</h4>
				<table class="mt-10 table">
					<colgroup>
						<col width="120px" />
						<col width="auto" />
					</colgroup>
					<tbody>
					<tr>
						<th>결제 상태</th>
						<td class="td-left" :class="'color-' + item.o_status_color ">{{ order_status }}</td>
					</tr>
					<tr>
						<th>결제금액</th>
						<td class="td-left" ><span class="color-blue font-weight-bold">{{ item.order_price | makeComma }}</span> 원</td>
					</tr>
					<tr>
						<th>결제</th>
						<td class="td-left" >{{ pay_div }}</td>
					</tr>
					<tr v-if="false" class=" mt-10 text-right ">
						{{ item.pay_info }}
					</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OrderResult'
	,props: ['Axios', 'codes', 'TOKEN']
	,data: function(){
		return {
			program: {
				name: '주문완료'
				,top: false
				,title: true
				,bottom: true
				,not_back: true
			}
			,item: {
				result: {

				}
				, supply_list: [

				]
				, order_number: this.order_num_new
			}
		}
	}
	,computed: {
		pay_div: function(){
			let div = ''
			if(this.item.pay_div == 'bank'){
				div = '무통장 입금'
			}else if(this.item.pay_div == 'card'){
				div = '카드 결제'
			}
			return div
		}
		,order_status: function(){
			let status = ''
			if(this.item.o_status == 1){
				status = '입금대기'
			}else{
				status = '결제완료'
			}
			return status
		}

		, is_m: function(){
			let t = false
			this.item.supply_list.filter(function(item){
				item.odt_list.filter(function(odt){
					if(odt.pdt_div == 'mobile'){
						t = true
						return
					}
				})
			})
			return t
		}
		,item_list: function(){

			let self = this
			return this.item.supply_list.filter(function(item){

				return item.odt_list.filter(function(odt){

					odt.order_status_name = self.codes.odt_status[odt.order_status].name
					odt.order_status_color = self.codes.odt_status[odt.order_status].color
					odt.options = {}
					if(odt.pdt_img1){
						odt.pdt_img = self.codes.img_url + odt.pdt_img1
					}
					switch(odt.order_status){
						case 'step4':
							odt.is_step5 = true
							odt.is_step31 = true
							odt.is_step41 = true
							break;
						case 'step1':
							odt.is_step21 = true
							break;
					}

					return odt
				})
			})
		}
	}
	,methods:{
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'order/getOrderDetail'
					,data: {
						order_num_new: this.$route.params.order_num_new
					}
				})

				if(result.success){
					this.item = result.data
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		,toShipping: function(odt){
			this.$emit('toShipping', odt)
		}
		, getDetail: async function (item) {
			try {
				const result = await this.$request.init({
					method: 'get'
					, url: 'order/getOrderMobileDetail'
					, data: {
						tid: item.tranSeq
						, service_code: item.cardNum
						, orderid: this.item.order_number
					}
				})

				if (result.success) {
					this.item_mobile = result.data
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.item_detail = item
				this.is_on_detail = true
			}
		}
	}
	,created() {
		this.$bus.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>

<style>
.pdt-img { width: 80px; height: auto; overflow: hidden}

.order-form h6 { margin-top: 10px; margin-bottom: 10px}
.order-form .box-shadow { margin-bottom: 30px}
</style>